'use strict';

import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import videojsqualityselector from 'videojs-hls-quality-selector';

$(function() {
    'use strict';

    document.querySelectorAll('.video-js').forEach( (element) => {

        let player = videojs(element, {
            html5: {
                vhs: {
                    smoothQualityChange: true,
                    withCredentials: true
                }
            }
        });

        player.hlsQualitySelector = videojsqualityselector;
        player.hlsQualitySelector({
            displayCurrentQuality: true,
        });

    })

});